.input-item {
    margin-bottom: 12px;
}

.input-item input {
    outline: none;
    height: 40px;
    min-width: 300px;
    border-radius: 4px;
    border: none;
    color: #000;
    padding: 0 10px;
}
